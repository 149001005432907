
.footer-col-book{
	ul{
		li{
			a{
				padding: 5px 0;
			}
		}
	}
}
