// Scroltop
.scroltop {
    background: var(--primary);
    border-color: var(--primary);
    border-radius: 50px;
    border-style: solid;
    border-width: 0;
    bottom: 15px;
    color: #fff!important;
    cursor: pointer;
    display: none;
    height: 50px;
    line-height: 50px;
    margin: 0;
    position: fixed;
    right: 15px;
    text-align: center;
    width: 50px;
    z-index: 999;
    box-shadow: -4px 4px 24px -10px;
    transition: all .8s;
    -moz-transition: all .8s;
    -webkit-transition: all .8s;
    -ms-transition: all .8s;
    -o-transition: all .8s;
	&:after{
		border: 1px dashed var(--primary);
		transform: scale(1.2);
		transition: all .5s;
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: inherit;
		content: '';
		top: 0;
		left: 0;
		padding: 0;
		z-index: -1;
		-webkit-transition: -webkit-transform .2s,opacity .3s;
		-moz-transition: -moz-transform .2s,opacity .3s;
		transition: transform .2s,opacity .3s;
	}
	&:hover{
		&:after{
			-webkit-animation: spinAround 9s linear infinite;
			-moz-animation: spinAround 9s linear infinite;
			animation: spinAround 9s linear infinite;
		}
	}
	@include respond('phone'){
		height: 40px;
		width: 40px;
		line-height: 40px;
	}
}

@-webkit-keyframes spinAround {
	from {
		-webkit-transform: rotate(0deg) scale(1.2)
	}
	to {
		-webkit-transform: rotate(360deg) scale(1.2);
	}
}
@-moz-keyframes spinAround {
	from {
		-moz-transform: rotate(0deg) scale(1.2)
	}
	to {
		-moz-transform: rotate(360deg) scale(1.2);
	}
}
@keyframes spinAround {
	from {
		transform: rotate(0deg) scale(1.2)
	}
	to {
		transform: rotate(360deg) scale(1.2);
	}
}