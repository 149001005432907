// text size
.font-10 {
    font-size: 10px;
}
.font-12 {
    font-size: 12px;
}
.font-13 {
    font-size: 13px;
}
.font-14 {
    font-size: 14px;
}
.font-16 {
    font-size: 16px;
}
.font-18 {
    font-size: 18px;
}
.font-20 {
    font-size: 20px;
}
.font-22 {
    font-size: 22px;
}
.font-24 {
    font-size: 24px;
}
.font-26 {
    font-size: 26px;
}
.font-30 {
    font-size: 30px;
}
.font-35 {
    font-size: 35px;
}
.font-40 {
    font-size: 40px;
}
.font-45 {
    font-size: 45px;
}
.font-50 {
    font-size: 50px;
}
.font-55 {
    font-size: 55px;
}
.font-60 {
    font-size: 60px;
}
.font-70 {
    font-size: 70px;
}
.font-75 {
    font-size: 75px;
}
.font-80 {
    font-size: 80px;
}
.font-90 {
    font-size: 90px;
}
.font-100 {
    font-size: 100px;
}
// Font Weight 
.font-weight-300 {
    font-weight: 300;
}
.font-weight-400 {
    font-weight: 400;
}
.font-weight-500 {
    font-weight: 500;
}
.font-weight-600 {
    font-weight: 600;
}
.font-weight-700 {
    font-weight: 700;
}
.font-weight-800 {
    font-weight: 800;
}
.font-weight-900 {
    font-weight: 900;
}
// PADDING (AROUND)
.p-a0 {
    padding: 0;
}
.p-a5 {
    padding: 5px;
}
.p-a10 {
    padding: 10px;
}
.p-a15 {
    padding: 15px;
}
.p-a20 {
    padding: 20px;
}
.p-a25 {
    padding: 25px;
}
.p-a30 {
    padding: 30px;
}
.p-a40 {
    padding: 40px;
}
.p-a50 {
    padding: 50px;
}
.p-a60 {
    padding: 60px;
}
.p-a70 {
    padding: 70px;
}
.p-a80 {
    padding: 80px;
}
.p-a90 {
    padding: 90px;
}
.p-a100 {
    padding: 100px;
}
// PADDING (TOP)

.p-t0 {
    padding-top: 0;
}
.p-t5 {
    padding-top: 5px;
}
.p-t10 {
    padding-top: 10px;
}
.p-t15 {
    padding-top: 15px;
}
.p-t20 {
    padding-top: 20px;
}
.p-t30 {
    padding-top: 30px;
}
.p-t40 {
    padding-top: 40px;
}
.p-t50 {
    padding-top: 50px;
}
.p-t60 {
    padding-top: 60px;
}
.p-t70 {
    padding-top: 70px;
}
.p-t80 {
    padding-top: 80px;
}
.p-t90 {
    padding-top: 90px;
}
.p-t100 {
    padding-top: 100px;
}
// PADDING (BOTTOM)

.p-b0 {
    padding-bottom: 0;
}
.p-b5 {
    padding-bottom: 5px;
}
.p-b10 {
    padding-bottom: 10px;
}
.p-b15 {
    padding-bottom: 15px;
}
.p-b20 {
    padding-bottom: 20px;
}
.p-b30 {
    padding-bottom: 30px;
}
.p-b40 {
    padding-bottom: 40px;
}
.p-b50 {
    padding-bottom: 50px;
}
.p-b60 {
    padding-bottom: 60px;
}
.p-b70 {
    padding-bottom: 70px;
}
.p-b80 {
    padding-bottom: 80px;
}
.p-b90 {
    padding-bottom: 90px;
}
.p-b100 {
    padding-bottom: 100px;
}
// PADDING (LEFT)

.p-l0 {
    padding-left: 0;
}
.p-l5 {
    padding-left: 5px;
}
.p-l10 {
    padding-left: 10px;
}
.p-l15 {
    padding-left: 15px;
}
.p-l20 {
    padding-left: 20px;
}
.p-l30 {
    padding-left: 30px;
}
.p-l40 {
    padding-left: 40px;
}
.p-l50 {
    padding-left: 50px;
}
.p-l60 {
    padding-left: 60px;
}
.p-l70 {
    padding-left: 70px;
}
.p-l80 {
    padding-left: 80px;
}
.p-l90 {
    padding-left: 90px;
}
.p-l100 {
    padding-left: 100px;
}
// PADDING (RIGHT)
.p-r0 {
    padding-right: 0;
}
.p-r5 {
    padding-right: 5px;
}
.p-r10 {
    padding-right: 10px;
}
.p-r15 {
    padding-right: 15px;
}
.p-r20 {
    padding-right: 20px;
}
.p-r30 {
    padding-right: 30px;
}
.p-r40 {
    padding-right: 40px;
}
.p-r50 {
    padding-right: 50px;
}
.p-r60 {
    padding-right: 60px;
}
.p-r70 {
    padding-right: 70px;
}
.p-r80 {
    padding-right: 80px;
}
.p-r90 {
    padding-right: 90px;
}
.p-r100 {
    padding-right: 100px;
}
// PADDING (LEFT RIGHT)
.p-lr0 {
    padding-left: 0;
    padding-right: 0;
}
.p-lr5 {
    padding-left: 5px;
    padding-right: 5px;
}
.p-lr10 {
    padding-left: 10px;
    padding-right: 10px;
}
.p-lr15 {
    padding-left: 15px;
    padding-right: 15px;
}
.p-lr20 {
    padding-left: 20px;
    padding-right: 20px;
}
.p-lr30 {
    padding-left: 30px;
    padding-right: 30px;
}
.p-lr40 {
    padding-left: 40px;
    padding-right: 40px;
}
.p-lr50 {
    padding-left: 50px;
    padding-right: 50px;
}
.p-lr60 {
    padding-left: 60px;
    padding-right: 60px;
}
.p-lr70 {
    padding-left: 70px;
    padding-right: 70px;
}
.p-lr80 {
    padding-left: 80px;
    padding-right: 80px;
}
.p-lr90 {
    padding-left: 90px;
    padding-right: 90px;
}
.p-lr100 {
    padding-left: 100px;
    padding-right: 100px;
}
// PADDING (TOP BOTTOM)

.p-tb0 {
    padding-bottom: 0;
    padding-top: 0;
}
.p-tb5 {
    padding-bottom: 5px;
    padding-top: 5px;
}
.p-tb10 {
    padding-bottom: 10px;
    padding-top: 10px;
}
.p-tb15 {
    padding-bottom: 15px;
    padding-top: 15px;
}
.p-tb20 {
    padding-bottom: 20px;
    padding-top: 20px;
}
.p-tb30 {
    padding-bottom: 30px;
    padding-top: 30px;
}
.p-tb40 {
    padding-bottom: 40px;
    padding-top: 40px;
}
.p-tb50 {
    padding-bottom: 50px;
    padding-top: 50px;
}
.p-tb60 {
    padding-bottom: 60px;
    padding-top: 60px;
}
.p-tb70 {
    padding-bottom: 70px;
    padding-top: 70px;
}
.p-tb80 {
    padding-bottom: 80px;
    padding-top: 80px;
}
.p-tb90 {
    padding-bottom: 90px;
    padding-top: 90px;
}
.p-tb100 {
    padding-bottom: 100px;
    padding-top: 100px;
}
// MARGIN (AROUND)

.m-auto {
    margin: auto;
}
.m-a0 {
    margin: 0;
}
.m-a5 {
    margin: 5px;
}
.m-a10 {
    margin: 10px;
}
.m-a15 {
    margin: 15px;
}
.m-a20 {
    margin: 20px;
}
.m-a30 {
    margin: 30px;
}
.m-a40 {
    margin: 40px;
}
.m-a50 {
    margin: 50px;
}
.m-a60 {
    margin: 60px;
}
.m-a70 {
    margin: 70px;
}
.m-a80 {
    margin: 80px;
}
.m-a90 {
    margin: 90px;
}
.m-a100 {
    margin: 100px;
}
// MARGING (TOP)

.m-t0 {
    margin-top: 0;
}
.m-t5 {
    margin-top: 5px;
}
.m-t10 {
    margin-top: 10px;
}
.m-t15 {
    margin-top: 15px;
}
.m-t18{
	margin-top: 18px;
}
.m-t20 {
    margin-top: 20px;
}
.m-t30 {
    margin-top: 30px;
}
.m-t40 {
    margin-top: 40px;
}
.m-t45 {
    margin-top: 45px;
}
.m-t50 {
    margin-top: 50px;
}
.m-t60 {
    margin-top: 60px;
}
.m-t70 {
    margin-top: 70px;
}
.m-t80 {
    margin-top: 80px;
}
.m-t90 {
    margin-top: 90px;
}
.m-t100 {
    margin-top: 100px;
}
// MARGING (BOTTOM)

.m-b0 {
    margin-bottom: 0;
}
.m-b5 {
    margin-bottom: 5px;
}
.m-b10 {
    margin-bottom: 10px;
}
.m-b15 {
    margin-bottom: 15px;
}
.m-b20 {
    margin-bottom: 20px;
}
.m-b25{
	margin-bottom: 25px;
}
.m-b30 {
    margin-bottom: 30px;
}
.m-b40 {
    margin-bottom: 40px;
}
.m-b50 {
    margin-bottom: 50px;
}
.m-b60 {
    margin-bottom: 60px;
}
.m-b70 {
    margin-bottom: 70px;
}
.m-b80 {
    margin-bottom: 80px;
}
.m-b90 {
    margin-bottom: 90px;
}
.m-b100 {
    margin-bottom: 100px;
}
// MARGING (LEFT)

.m-l0 {
    margin-left: 0;
}
.m-l5 {
    margin-left: 5px;
}
.m-l10 {
    margin-left: 10px;
}
.m-l15 {
    margin-left: 15px;
}
.m-l20 {
    margin-left: 20px;
}
.m-l30 {
    margin-left: 30px;
}
.m-l40 {
    margin-left: 40px;
}
.m-l50 {
    margin-left: 50px;
}
.m-l60 {
    margin-left: 60px;
}
.m-l70 {
    margin-left: 70px;
}
.m-l80 {
    margin-left: 80px;
}
.m-l90 {
    margin-left: 90px;
}
.m-l100 {
    margin-left: 100px;
}
// MARGING (RIGHT)

.m-r0 {
    margin-right: 0;
}
.m-r5 {
    margin-right: 5px;
}
.m-r10 {
    margin-right: 10px;
}
.m-r15 {
    margin-right: 15px;
}
.m-r20 {
    margin-right: 20px;
}
.m-r30 {
    margin-right: 30px;
}
.m-r40 {
    margin-right: 40px;
}
.m-r50 {
    margin-right: 50px;
}
.m-r60 {
    margin-right: 60px;
}
.m-r70 {
    margin-right: 70px;
}
.m-r80 {
    margin-right: 80px;
}
.m-r90 {
    margin-right: 90px;
}
.m-r100 {
    margin-right: 100px;
}
// MARGING (LEFT RIGHT)
.m-lr0 {
    margin-left: 0;
    margin-right: 0;
}
.m-lr5 {
    margin-left: 5px;
    margin-right: 5px;
}
.m-lr10 {
    margin-left: 10px;
    margin-right: 10px;
}
.m-lr15 {
    margin-left: 15px;
    margin-right: 15px;
}
.m-lr20 {
    margin-left: 20px;
    margin-right: 20px;
}
.m-lr30 {
    margin-left: 30px;
    margin-right: 30px;
}
.m-lr40 {
    margin-left: 40px;
    margin-right: 40px;
}
.m-lr50 {
    margin-left: 50px;
    margin-right: 50px;
}
.m-lr60 {
    margin-left: 60px;
    margin-right: 60px;
}
.m-lr70 {
    margin-left: 70px;
    margin-right: 70px;
}
.m-lr80 {
    margin-left: 80px;
    margin-right: 80px;
}
.m-lr90 {
    margin-left: 90px;
    margin-right: 90px;
}
.m-lr100 {
    margin-left: 100px;
    margin-right: 100px;
}
// MARGING (TOP BOTTOM)
.m-tb0 {
    margin-bottom: 0;
    margin-top: 0;
}
.m-tb5 {
    margin-bottom: 5px;
    margin-top: 5px;
}
.m-tb10 {
    margin-bottom: 10px;
    margin-top: 10px;
}
.m-tb15 {
    margin-bottom: 15px;
    margin-top: 15px;
}
.m-tb20 {
    margin-bottom: 20px;
    margin-top: 20px;
}
.m-tb30 {
    margin-bottom: 30px;
    margin-top: 30px;
}
.m-tb40 {
    margin-bottom: 40px;
    margin-top: 40px;
}
.m-tb50 {
    margin-bottom: 50px;
    margin-top: 50px;
}
.m-tb60 {
    margin-bottom: 60px;
    margin-top: 60px;
}
.m-tb70 {
    margin-bottom: 70px;
    margin-top: 70px;
}
.m-tb80 { margin-bottom: 80px; margin-top: 80px;}
.m-tb90 { margin-bottom: 90px; margin-top: 90px; }
.m-tb100 { margin-bottom: 100px; margin-top: 100px; }


@media only screen and (max-width: 1200px) {
	.m-lg-t0   	{ margin-top: 0;     }
	.m-lg-t5   	{ margin-top: 5px;   }
	.m-lg-t10 	{ margin-top: 10px;  }
	.m-lg-t15 	{ margin-top: 15px;  }
	.m-lg-t20 	{ margin-top: 20px;  }
	.m-lg-t30 	{ margin-top: 30px;  }
	.m-lg-t40 	{ margin-top: 40px;  }
	.m-lg-t50 	{ margin-top: 50px;  }
	.m-lg-t60 	{ margin-top: 60px;  }
	.m-lg-t70 	{ margin-top: 70px;  }
	.m-lg-t80 	{ margin-top: 80px;  }
	.m-lg-t90 	{ margin-top: 90px;  }
	.m-lg-t100    { margin-top: 100px; }
	
	.m-lg-b0   	{ margin-bottom: 0;     }
	.m-lg-b5   	{ margin-bottom: 5px;   }
	.m-lg-b10 	{ margin-bottom: 10px;  }
	.m-lg-b15 	{ margin-bottom: 15px;  }
	.m-lg-b20 	{ margin-bottom: 20px;  }
	.m-lg-b30 	{ margin-bottom: 30px;  }
	.m-lg-b40 	{ margin-bottom: 40px;  }
	.m-lg-b50 	{ margin-bottom: 50px;  }
	.m-lg-b60 	{ margin-bottom: 60px;  }
	.m-lg-b70 	{ margin-bottom: 70px;  }
	.m-lg-b80 	{ margin-bottom: 80px;  }
	.m-lg-b90 	{ margin-bottom: 90px;  }
	.m-lg-b100    { margin-bottom: 100px; }
}
@media only screen and (max-width: 991px) {
	.m-md-t0   	{ margin-top: 0;     }
	.m-md-t5   	{ margin-top: 5px;   }
	.m-md-t10 	{ margin-top: 10px;  }
	.m-md-t15 	{ margin-top: 15px;  }
	.m-md-t20 	{ margin-top: 20px;  }
	.m-md-t30 	{ margin-top: 30px;  }
	.m-md-t40 	{ margin-top: 40px;  }
	.m-md-t50 	{ margin-top: 50px;  }
	.m-md-t60 	{ margin-top: 60px;  }
	.m-md-t70 	{ margin-top: 70px;  }
	.m-md-t80 	{ margin-top: 80px;  }
	.m-md-t90 	{ margin-top: 90px;  }
	.m-md-t100    { margin-top: 100px; }
	
	.m-md-b0   	{ margin-bottom: 0;     }
	.m-md-b5   	{ margin-bottom: 5px;   }
	.m-md-b10 	{ margin-bottom: 10px;  }
	.m-md-b15 	{ margin-bottom: 15px;  }
	.m-md-b20 	{ margin-bottom: 20px;  }
	.m-md-b30 	{ margin-bottom: 30px;  }
	.m-md-b40 	{ margin-bottom: 40px;  }
	.m-md-b50 	{ margin-bottom: 50px;  }
	.m-md-b60 	{ margin-bottom: 60px;  }
	.m-md-b70 	{ margin-bottom: 70px;  }
	.m-md-b80 	{ margin-bottom: 80px;  }
	.m-md-b90 	{ margin-bottom: 90px;  }
	.m-md-b100    { margin-bottom: 100px; }
	.m-md-r0 {margin-right:0;}
	.p-md-r0{padding-right:0;}
	.p-md-t40{padding-top:40px;}
	.p-md-b40{padding-bottom:40px;}
	.p-md-l0{padding-left:0;}
}
@media only screen and (max-width: 767px) {
	.m-sm-t0   	{ margin-top: 0;     }
	.m-sm-t5   	{ margin-top: 5px;   }
	.m-sm-t10 	{ margin-top: 10px;  }
	.m-sm-t15 	{ margin-top: 15px;  }
	.m-sm-t20 	{ margin-top: 20px;  }
	.m-sm-t30 	{ margin-top: 30px;  }
	.m-sm-t40 	{ margin-top: 40px;  }
	.m-sm-t50 	{ margin-top: 50px;  }
	.m-sm-t60 	{ margin-top: 60px;  }
	.m-sm-t70 	{ margin-top: 70px;  }
	.m-sm-t80 	{ margin-top: 80px;  }
	.m-sm-t90 	{ margin-top: 90px;  }
	.m-sm-t100    { margin-top: 100px; }
	
	.m-sm-b0   	{ margin-bottom: 0;     }
	.m-sm-b5   	{ margin-bottom: 5px;   }
	.m-sm-b10 	{ margin-bottom: 10px;  }
	.m-sm-b15 	{ margin-bottom: 15px;  }
	.m-sm-b20 	{ margin-bottom: 20px;  }
	.m-sm-b30 	{ margin-bottom: 30px;  }
	.m-sm-b40 	{ margin-bottom: 40px;  }
	.m-sm-b50 	{ margin-bottom: 50px;  }
	.m-sm-b60 	{ margin-bottom: 60px;  }
	.m-sm-b70 	{ margin-bottom: 70px;  }
	.m-sm-b80 	{ margin-bottom: 80px;  }
	.m-sm-b90 	{ margin-bottom: 90px;  }
	.m-sm-b100    { margin-bottom: 100px; }
	.m-sm-r10 {margin-right:10px;}
}
@media only screen and (max-width: 576px) {
	.m-xs-t0   	{ margin-top: 0;     }
	.m-xs-t5   	{ margin-top: 5px;   }
	.m-xs-t10 	{ margin-top: 10px;  }
	.m-xs-t15 	{ margin-top: 15px;  }
	.m-xs-t20 	{ margin-top: 20px;  }
	.m-xs-t30 	{ margin-top: 30px;  }
	.m-xs-t40 	{ margin-top: 40px;  }
	.m-xs-t50 	{ margin-top: 50px;  }
	.m-xs-t60 	{ margin-top: 60px;  }
	.m-xs-t70 	{ margin-top: 70px;  }
	.m-xs-t80 	{ margin-top: 80px;  }
	.m-xs-t90 	{ margin-top: 90px;  }
	.m-xs-t100    { margin-top: 100px; }
	
	.m-xs-b0   	{ margin-bottom: 0;     }
	.m-xs-b5   	{ margin-bottom: 5px;   }
	.m-xs-b10 	{ margin-bottom: 10px;  }
	.m-xs-b15 	{ margin-bottom: 15px;  }
	.m-xs-b20 	{ margin-bottom: 20px;  }
	.m-xs-b30 	{ margin-bottom: 30px;  }
	.m-xs-b40 	{ margin-bottom: 40px;  }
	.m-xs-b50 	{ margin-bottom: 50px;  }
	.m-xs-b60 	{ margin-bottom: 60px;  }
	.m-xs-b70 	{ margin-bottom: 70px;  }
	.m-xs-b80 	{ margin-bottom: 80px;  }
	.m-xs-b90 	{ margin-bottom: 90px;  }
	.m-xs-b100    { margin-bottom: 100px; }
}


@media only screen and (max-width: 1200px) {
	.p-lg-t0   	{ padding-top: 0;     }
	.p-lg-t5   	{ padding-top: 5px;   }
	.p-lg-t10 	{ padding-top: 10px;  }
	.p-lg-t15 	{ padding-top: 15px;  }
	.p-lg-t20 	{ padding-top: 20px;  }
	.p-lg-t30 	{ padding-top: 30px;  }
	.p-lg-t40 	{ padding-top: 40px;  }
	.p-lg-t50 	{ padding-top: 50px;  }
	.p-lg-t60 	{ padding-top: 60px;  }
	.p-lg-t70 	{ padding-top: 70px;  }
	.p-lg-t80 	{ padding-top: 80px;  }
	.p-lg-t90 	{ padding-top: 90px;  }
	.p-lg-t100    { padding-top: 100px; }
	
	.p-lg-b0   	{ padding-bottom: 0;     }
	.p-lg-b5   	{ padding-bottom: 5px;   }
	.p-lg-b10 	{ padding-bottom: 10px;  }
	.p-lg-b15 	{ padding-bottom: 15px;  }
	.p-lg-b20 	{ padding-bottom: 20px;  }
	.p-lg-b30 	{ padding-bottom: 30px;  }
	.p-lg-b40 	{ padding-bottom: 40px;  }
	.p-lg-b50 	{ padding-bottom: 50px;  }
	.p-lg-b60 	{ padding-bottom: 60px;  }
	.p-lg-b70 	{ padding-bottom: 70px;  }
	.p-lg-b80 	{ padding-bottom: 80px;  }
	.p-lg-b90 	{ padding-bottom: 90px;  }
	.p-lg-b100    { padding-bottom: 100px; }
}
@media only screen and (max-width: 991px) {
	.p-md-t0   	{ padding-top: 0;     }
	.p-md-t5   	{ padding-top: 5px;   }
	.p-md-t10 	{ padding-top: 10px;  }
	.p-md-t15 	{ padding-top: 15px;  }
	.p-md-t20 	{ padding-top: 20px;  }
	.p-md-t30 	{ padding-top: 30px;  }
	.p-md-t40 	{ padding-top: 40px;  }
	.p-md-t50 	{ padding-top: 50px;  }
	.p-md-t60 	{ padding-top: 60px;  }
	.p-md-t70 	{ padding-top: 70px;  }
	.p-md-t80 	{ padding-top: 80px;  }
	.p-md-t90 	{ padding-top: 90px;  }
	.p-md-t100    { padding-top: 100px; }
	
	.p-md-b0   	{ padding-bottom: 0;     }
	.p-md-b5   	{ padding-bottom: 5px;   }
	.p-md-b10 	{ padding-bottom: 10px;  }
	.p-md-b15 	{ padding-bottom: 15px;  }
	.p-md-b20 	{ padding-bottom: 20px;  }
	.p-md-b30 	{ padding-bottom: 30px;  }
	.p-md-b40 	{ padding-bottom: 40px;  }
	.p-md-b50 	{ padding-bottom: 50px;  }
	.p-md-b60 	{ padding-bottom: 60px;  }
	.p-md-b70 	{ padding-bottom: 70px;  }
	.p-md-b80 	{ padding-bottom: 80px;  }
	.p-md-b90 	{ padding-bottom: 90px;  }
	.p-md-b100    { padding-bottom: 100px; }
	.p-md-r0 {margin-right:0;}
	.p-md-r0{padding-right:0;}
	.p-md-t40{padding-top:40px;}
	.p-md-b40{padding-bottom:40px;}
	.p-md-l0{padding-left:0;}
}
@media only screen and (max-width: 767px) {
	.p-sm-t0   	{ padding-top: 0;     }
	.p-sm-t5   	{ padding-top: 5px;   }
	.p-sm-t10 	{ padding-top: 10px;  }
	.p-sm-t15 	{ padding-top: 15px;  }
	.p-sm-t20 	{ padding-top: 20px;  }
	.p-sm-t30 	{ padding-top: 30px;  }
	.p-sm-t40 	{ padding-top: 40px;  }
	.p-sm-t50 	{ padding-top: 50px;  }
	.p-sm-t60 	{ padding-top: 60px;  }
	.p-sm-t70 	{ padding-top: 70px;  }
	.p-sm-t80 	{ padding-top: 80px;  }
	.p-sm-t90 	{ padding-top: 90px;  }
	.p-sm-t100    { padding-top: 100px; }
	
	.p-sm-b0   	{ padding-bottom: 0;     }
	.p-sm-b5   	{ padding-bottom: 5px;   }
	.p-sm-b10 	{ padding-bottom: 10px;  }
	.p-sm-b15 	{ padding-bottom: 15px;  }
	.p-sm-b20 	{ padding-bottom: 20px;  }
	.p-sm-b30 	{ padding-bottom: 30px;  }
	.p-sm-b40 	{ padding-bottom: 40px;  }
	.p-sm-b50 	{ padding-bottom: 50px;  }
	.p-sm-b60 	{ padding-bottom: 60px;  }
	.p-sm-b70 	{ padding-bottom: 70px;  }
	.p-sm-b80 	{ padding-bottom: 80px;  }
	.p-sm-b90 	{ padding-bottom: 90px;  }
	.p-sm-b100    { padding-bottom: 100px; }
	.p-sm-r10 {padding-right:10px;}
}
@media only screen and (max-width: 576px) {
	.p-xs-t0   	{ padding-top: 0;     }
	.p-xs-t5   	{ padding-top: 5px;   }
	.p-xs-t10 	{ padding-top: 10px;  }
	.p-xs-t15 	{ padding-top: 15px;  }
	.p-xs-t20 	{ padding-top: 20px;  }
	.p-xs-t30 	{ padding-top: 30px;  }
	.p-xs-t40 	{ padding-top: 40px;  }
	.p-xs-t50 	{ padding-top: 50px;  }
	.p-xs-t60 	{ padding-top: 60px;  }
	.p-xs-t70 	{ padding-top: 70px;  }
	.p-xs-t80 	{ padding-top: 80px;  }
	.p-xs-t90 	{ padding-top: 90px;  }
	.p-xs-t100    { padding-top: 100px; }
	
	.p-xs-b0   	{ padding-bottom: 0;     }
	.p-xs-b5   	{ padding-bottom: 5px;   }
	.p-xs-b10 	{ padding-bottom: 10px;  }
	.p-xs-b15 	{ padding-bottom: 15px;  }
	.p-xs-b20 	{ padding-bottom: 20px;  }
	.p-xs-b30 	{ padding-bottom: 30px;  }
	.p-xs-b40 	{ padding-bottom: 40px;  }
	.p-xs-b50 	{ padding-bottom: 50px;  }
	.p-xs-b60 	{ padding-bottom: 60px;  }
	.p-xs-b70 	{ padding-bottom: 70px;  }
	.p-xs-b80 	{ padding-bottom: 80px;  }
	.p-xs-b90 	{ padding-bottom: 90px;  }
	.p-xs-b100  { padding-bottom: 100px; }
}
// BOX MAX-WIDTH CSS
.max-w50 {
    max-width: 50px;
}
.max-w60 {
    max-width: 60px;
}
.max-w80 {
    max-width: 80px;
}
.max-w100 {
    max-width: 100px;
}
.max-w200 {
    max-width: 200px;
}
.max-w300 {
    max-width: 300px;
}
.max-w400 {
    max-width: 400px;
}
.max-w500 {
    max-width: 500px;
}
.max-w600 {
    max-width: 600px;
}
.max-w700 {
    max-width: 700px;
}
.max-w800 {
    max-width: 800px;
}
.max-w900 {
    max-width: 900px;
}
.max-w1000 {
    max-width: 1000px;
}
// BACKGROUND IMAGE
.bg-img-fix {
    background-attachment: unset;
	background-size: cover;
}
.full-img{
	width:100%;
}

.sticky-top{
	top:120px;
}
.page-title {
    padding : 65px 0px 45px;
    position: relative;
    background         : $light;
    background-size    : cover;
    background-position: center;

    p {
        font-size     : 20px;
        font-weight  : 500;
        margin-bottom : 5px;
        color         : #7272A8;
        text-transform: capitalize;
		@include respond('phone'){
			font-size:16px;
		}
    }

    h3 {
        font-size     : 40px;
        line-height   : 1.2;
        font-weight  : 600;
        text-transform: capitalize;
		@include respond('phone'){
			font-size: 28px;
		}
    }
}
.img-cover {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
section{
    position: relative;
	z-index: 1;
}
.section-head{
	margin-bottom:40px;
	
	@include respond('phone-land'){
		margin-bottom:20px;
	}
	h1, h2, h3, h4, h5, h6{
		font-family: var(--font-family-title);
	}
	h2{
		font-size: 2.625rem;
		font-weight:600;
		
		@include respond ('laptop'){
			font-size:2.25rem;
		}
		@include respond ('phone-land'){
			font-size:1.625rem;
		}
	}
	h3{
		font-size:2.25rem;
		font-weight:600;
		line-height: 1.2;
		@include custommq ($max:1200px){
			font-size:2.15rem;
		}
		@include respond ('tab-port'){
			font-size:1.75rem;
		}
	}
	h4{
		font-size:2rem;
		@include respond('phone'){
			font-size:1.5rem;
		}
	}
	p{
		font-size: 16px;
		line-height: 1.5;	
		font-weight: 400;	
		@include respond('phone-land'){
			font-size: 14px;
		}		
	}
	&.text-center p{
		max-width:753px;
		margin-left:auto;
		margin-right:auto;
	}
	&.m-b30{
		margin-bottom:30px;
	}
	.sub-title{
		text-transform:uppercase;
	}
}

.row.spno,
.spno{
	margin-left:0;
	margin-right:0;
	
	[class*="col"],
	[class*="col"]{
		padding-left:0;
		padding-right:0;
	}
}
.row.sp4,
.sp4{
	margin-left:-4px;
	margin-right:-4px;
	
	[class*="col"],
	[class*="col"]{
		padding-left:4px;
		padding-right:4px;
	}
}
.row.sp15,
.sp15{
	margin-left:-7px;
	margin-right:-7px;
	
	[class*="col"],
	[class*="col"]{
		padding-left:7px;
		padding-right:7px;
	}
}
.row.sp10,
.sp10{
	margin-left:-10px;
	margin-right:-10px;
	[class*="col"],
	[class*="col"]{
		padding-left:10px;
		padding-right:10px;
	}
}

// rounded
.rounded-xl{
	border-radius: 50% !important;
	overflow: hidden;
}
.rounded-lg{
	border-radius: 20px !important;
	overflow: hidden;
}
.rounded-md{
	border-radius: $border-radius-base !important;
	overflow: hidden;
}
.rounded-sm{
	border-radius: 4px !important;
	overflow: hidden;
}


// text color
.text-maroon{
	color: $maroon;
}
.text-orange{
	color: $orange;
}
.text-yellow{
	color: $yellow;
}
.text-skyblue{
	color: $green;
}
.text-red{
	color: $red;
}
.text-green{
	color: $green;
}
.text-blue{
	color: $blue;
}

// bg color
.bg-maroon{
	background-color: $maroon;
}
.bg-orange{
	background-color: $orange;
}
.bg-yellow{
	background-color: $yellow;
}
.bg-skyblue{
	background-color: $skyblue;
}
.bg-red{
	background-color: $red;
}
.bg-green{
	background-color: $green;
}
.bg-blue{
	background-color: $blue;
}
// Scale
.scale8{
	transform:scale(1.85);
	-moz-transform:scale(1.85);
	-webkit-transform:scale(1.85);
	-ms-transform:scale(1.85);
	-o-transform:scale(1.85);
}
.scale7{
	transform:scale(1.7);
	-moz-transform:scale(1.7);
	-webkit-transform:scale(1.7);
	-ms-transform:scale(1.7);
	-o-transform:scale(1.7);
}
.scale5{
	@include transformScaleMD;
}
.scale3{
	transform:scale(1.3);
	-moz-transform:scale(1.3);
	-webkit-transform:scale(1.3);
	-ms-transform:scale(1.3);
	-o-transform:scale(1.3);
}
.scale2{
	@include transformScaleSM;
}
.scale08{
	@include transformScaleXS;
}
.scale05{
	@include transformScaleXS1;
}
.tl{
	transform-origin: left;
	-moz-transform-origin: left;
	-webkit-transform-origin: left;
	-ms-transform-origin: left;
	-o-transform-origin: left;
}
.shadow-none{
	box-shadow: unset;
}





/*==== Shortcode Css ====*/
.sort-title{
	margin-bottom:40px;
}
.sort-title h4:after{
	left: -160px;
}
.sort-title.text-white h4:after,
.sort-title.text-white h4:before {
	background:#fff;
}
.sort-title h4:after,
.sort-title h4:before {
    background: #000 none repeat scroll 0 0;
    content: "";
    height: 1px;
    position: absolute;
    right: -160px;
    top: 50%;
    width: 150px;
	opacity: 0.15;
}
.sort-title h4 {
    display: inline-block;
    margin: 0;
    position: relative;
}
@media only screen and (max-width: 767px) {
    .sort-title h4:after,
	.sort-title h4:before {
		content:none;
	}
}