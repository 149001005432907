// dz-bnr-inr
.dz-bnr-inr{
    height: 450px;
    background-size: cover;
	background-position: center;
	position: relative;
	z-index:2;
	width: 100%;
		
	@include respond('tab-port'){
		height: 300px;
	}
	@include respond('phone'){
		height: 230px;
	}
	.container{
		display: table;
		height: 100%;
		
	}
	.dz-bnr-inr-entry{
	    height: 400px;
		vertical-align: middle;
		display: table-cell;
	    text-align: center;
		@include respond('tab-port'){
			height: 300px;
		}		
		@include respond('phone-land'){
			text-align:center;
			.breadcrumb-row{
				display:inline-block;
			}
		}	
		@include respond('phone'){
			height: 230px;
		}	
	}
	h1 {
		font-size: 3.5rem;
		margin-bottom: 10px;
		line-height: 1.2;
		color:#fff;
		text-transform: capitalize;
		@include respond('tab-port'){
			font-size: 40px;
		}
		@include respond('phone-land'){
			font-size: 30px;
		}
	}
	p{
		color:#fff;
		font-size:18px;
		max-width: 560px;
		margin-left: auto;
		margin-right: auto;

		@include respond ('phone'){
			font-size:14px;
		}
	}
	
	// height sm
	&.dz-bnr-inr-lg{
		height: 600px;
		.dz-bnr-inr-entry{
			height: 600px;
		}
		@include respond('tab-port'){
			height: 450px;
			.dz-bnr-inr-entry{
				height: 450px;
			}
		}
		@include respond('phone-land'){
			height: 350px;
			.dz-bnr-inr-entry{
				height: 350px;
			}
		}
		@include respond('phone'){
			height: 300px;
			.dz-bnr-inr-entry{
				height: 300px;
			}
		}
	}
	
	&.dz-bnr-inr-md{
		height:450px;
		.dz-bnr-inr-entry{
			height: 450px;
		}
		@include respond ('tab-port'){
			height:400px;
			.dz-bnr-inr-entry{
				height: 400px;
			}
		}
		@include respond('phone'){
			height:300px;
			.dz-bnr-inr-entry{
				height: 300px;
			}
		}
	}
	
	// height sm
	&.dz-bnr-inr-sm{
		height: 300px;
		.dz-bnr-inr-entry{
			height: 300px;
		}
	}
	&.bnr-no-img:before{
		content:none;
	}
	&:after{
		position:absolute;
		top:0;
		right:0;
		content:"";
		height:100%;
		width:60%;
		background-color:#fff;
		opacity:.15;
		clip-path: polygon(100% 0, 0% 100%, 100% 100%);
	}
	.breadcrumb-row{
		position:absolute;
		left:50%;
		transform:translateX(-50%);
		bottom:-20px;
		ul{
			margin-bottom:0;
		}
		@include respond ('tab-port'){
			left:15px;
			right:15px;
			transform:translateX(0px);
		}
	}
	
	
}
.breadcrumb-row{
	ul {
		background:var(--primary);
		padding: 0;
		display:inline-block;
		padding: 15px 30px;
		border-radius:var(--border-radius-base);
		li {
			padding: 0;
			margin-right: 3px;
			display: inline-block;
			font-size: 16px;
			font-weight: 400;
			text-transform: uppercase;
			color: $white;
			&.active{
				color: $white;			
			}
			a{
				color: $white;
			}
		}
		.breadcrumb-item + .breadcrumb-item::before{
			content: "\f105";
			color: $white;
			font-weight: 700;
			font-family: "Font Awesome 5 Free";
			font-size: 15px;
			padding-right: 10px;
			top: 0px;
			position: relative;
			@include respond ('phone-land'){
				top: 2px;
			}
		}
		@include respond ('phone-land'){
			padding: 5px 20px;
			li{
				font-size:15px;
			}
		}
	}
}
